import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; // Ensure this import is correct

const firebaseConfig = {
  // apiKey: "AIzaSyA7lQCwdVLpOb4LzmmyXeX5ClrZy_ohM1A",
  // projectId: "coinbet-d5c48",
  // storageBucket: "coinbet-d5c48.firebasestorage.app",
  apiKey: "AIzaSyDWdHSOdvbHMPTyNOB0bf7eHJ9W-pYSweo",
  projectId: "target100-f19be",
  storageBucket: "target100-f19be.firebasestorage.app",
  // apiKey: "AIzaSyBdRzB0khfBaZ5UBAPNigqdvgUpmedJEOI",
  // projectId: "target100-e0f14",
  // storageBucket: "target100-e0f14.appspot.com"
};
const fireBase = initializeApp(firebaseConfig);
const storage = getStorage(fireBase);
const db = getFirestore(fireBase);

export { fireBase, db, storage };
